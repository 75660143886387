import { useState } from "react";
import { useEffect } from "react";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline ,DirectionsRenderer} from "react-google-maps"
import { Polygon } from "react-google-maps";
import { robotIcon } from './robotIcon';
import MedianIncomeKml from "./testmap.kml";
import { KmlLayer } from "react-google-maps"
import ReactGoogleMapLoader from "react-google-maps-loader"
// import { DistanceMatrixService } from "@react-google-maps/api";
import * as React from 'react';
let directions=''
var image = {
  
}
let origin;
let destination;
let fivesec=true
let kmlurl= localStorage.getItem('kmlurl')
const googlemapcon=(props)=>{
  // console.log('Inside Direction Service')
  if(fivesec){
    
    const directionsService = new window.google.maps.DirectionsService();
    image={
     url: '/assets/images/robot-icon-pickup.svg',
     // url: '/assets/images/robot-icon-dropoff.svg',
     // size:new window.google.maps.Size(50, 50),
     scaledSize: new window.google.maps.Size(25, 25)
     }
    if(props.commandlistdata?.length >0){
      origin = { lat: parseFloat(props.commandlistdata[0]?.latitude), lng: parseFloat(props.commandlistdata[0]?.longitude)};
      destination = { lat: parseFloat(props.robotlatitude), lng: parseFloat(props.robotlongitude)};
      directionsService.route(
       {   
         origin: origin,
         destination: destination,
         travelMode: window.google.maps.TravelMode.WALKING
       },
       (result, status) => {
         if (status === window.google.maps.DirectionsStatus.OK) {
           directions =result
         } else {
           console.error(`error fetching directions ${result}`);
         }
       }
     );
    }
    fivesec=false
    console.log('inside 5 second interval')
}
    setInterval(()=>{
        fivesec=true
  }, 80000)
 
}
const path2= [
    {
        "lng":-89.6228363,
        "lat":40.6965266
    },
    {
        "lng":-89.6228323,
        "lat":40.6969577
    },
    {
        "lng":-89.6228243,
        "lat":40.6974132
    },
    {
        "lng":-89.6228229,
        "lat":40.697879
    },
    {
        "lng":-89.6228246,
        "lat":40.6983357
    },
    {
        "lng":-89.6228112,
        "lat":40.6987373
    },
    {
        "lng":-89.6228074,
        "lat":40.6989727
    },
    {
        "lng":-89.6227886,
        "lat":40.6990815
    },
    {
        "lng":-89.6227632,
        "lat":40.6991771
    },
    {
        "lng":-89.6227323,
        "lat":40.6992635
    },
    {
        "lng":-89.6226974,
        "lat":40.6993397
    },
    {
        "lng":-89.6226426,
        "lat":40.6994127
    },
    {
        "lng":-89.6225984,
        "lat":40.6994645
    },
    {
        "lng":-89.6225367,
        "lat":40.6995123
    },
    {
        "lng":-89.6224723,
        "lat":40.6995459
    },
    {
        "lng":-89.6224106,
        "lat":40.6995733
    },
    {
        "lng":-89.6223583,
        "lat":40.6995916
    },
    {
        "lng":-89.6223086,
        "lat":40.6996099
    },
    {
        "lng":-89.6222563,
        "lat":40.6996201
    },
    {
        "lng":-89.6221997,
        "lat":40.6996385
    },
    {
        "lng":-89.6221447,
        "lat":40.6996497
    },
    {
        "lng":-89.622077,
        "lat":40.6996629
    },
    {
        "lng":-89.6220113,
        "lat":40.6996812
    },
    {
        "lng":-89.6219214,
        "lat":40.6997026
    },
    {
        "lng":-89.6218597,
        "lat":40.6997199
    },
    {
        "lng":-89.6217967,
        "lat":40.699731
    },
    {
        "lng":-89.6217377,
        "lat":40.6997432
    },
    {
        "lng":-89.6216867,
        "lat":40.6997524
    },
    {
        "lng":-89.621625,
        "lat":40.6997656
    },
    {
        "lng":-89.6215602,
        "lat":40.6997802
    },
    {
        "lng":-89.6214757,
        "lat":40.6997944
    },
    {
        "lng":-89.6213711,
        "lat":40.6998117
    },
    {
        "lng":-89.6213134,
        "lat":40.6998209
    },
    {
        "lng":-89.6212702,
        "lat":40.699828
    },
    {
        "lng":-89.6212193,
        "lat":40.6998341
    },
    {
        "lng":-89.6211723,
        "lat":40.6998402
    },
    {
        "lng":-89.621109,
        "lat":40.6998484
    },
    {
        "lng":-89.6210598,
        "lat":40.6998535
    },
    {
        "lng":-89.6210102,
        "lat":40.6998545
    },
    {
        "lng":-89.6209378,
        "lat":40.6998596
    },
    {
        "lng":-89.6205401,
        "lat":40.6998645
    },
    {
        "lng":-89.6197073,
        "lat":40.6998509
    },
    {
        "lng":-89.6184532,
        "lat":40.6998193
    },
    {
        "lng":-89.617482,
        "lat":40.6998092
    },
    {
        "lng":-89.6153585,
        "lat":40.6997926
    },
    {
        "lng":-89.6133951,
        "lat":40.6997923
    },
    {
        "lng":-89.6130008,
        "lat":40.6997857
    },
    {
        "lng":-89.6116597,
        "lat":40.6997695
    },
    {
        "lng":-89.6105637,
        "lat":40.6997523
    },
    {
        "lng":-89.6100432,
        "lat":40.6997513
    },
    {
        "lng":-89.6100325,
        "lat":40.698943
    },
    {
        "lng":-89.6100041,
        "lat":40.6974904
    },
    {
        "lng":-89.6099934,
        "lat":40.6965387
    },
    {
        "lng":-89.6099963,
        "lat":40.6969269
    },
    {
        "lng":-89.609993,
        "lat":40.6963687
    },
    {
        "lng":-89.6100453,
        "lat":40.6963575
    },
    {
        "lng":-89.6100895,
        "lat":40.6963504
    },
    {
        "lng":-89.6101995,
        "lat":40.6963484
    },
    {
        "lng":-89.6103605,
        "lat":40.6963494
    },
    {
        "lng":-89.6106949,
        "lat":40.696346
    },
    {
        "lng":-89.6131212,
        "lat":40.6963772
    },
    {
        "lng":-89.6145144,
        "lat":40.6963903
    },
    {
        "lng":-89.622837,
        "lat":40.6964059
    }
]


const path3=[
    {
     "lat": 40.69970419,
     "lng": -89.61329873
    },
    {
     "lat": 40.69970288,
     "lng": -89.61312298
    },
    {
     "lat": 40.6997327,
     "lng": -89.61308164
    },
    {
     "lat": 40.69973322,
     "lng": -89.61333791
    },
   ]
   const path4=[
    {
     "lat": 40.69798379,
     "lng": -89.61326391
    },
    {
     "lat": 40.69796565,
     "lng": -89.613239
    },
    {
     "lat": 40.697967,
     "lng": -89.61312003
    },
    {
     "lat": 40.69798129,
     "lng": -89.61310737
    },
   ]
   const path5=[
    {
     "lat": 40.69648867,
     "lng": -89.62005255
    },
    {
     "lat": 40.69647467,
     "lng": -89.62005733
    },
    {
     "lat": 40.69647299,
     "lng": -89.61986518
    },
    {
     "lat": 40.69648955,
     "lng": -89.61987225
    },
   ]
   const path6=[
    {
     "lat": 40.69957369,
     "lng": -89.62202151
    },
    {
     "lat": 40.69955938,
     "lng": -89.6220849
    },
    {
     "lat": 40.69957185,
     "lng": -89.62209547
    },
    {
     "lat": 40.69959204,
     "lng": -89.62200718
    },
   ]
   const path7=[
    {
     "lat": 40.69971361,
     "lng": -89.61738625
    },
    {
     "lat": 40.69969705,
     "lng": -89.61737636
    },
    {
     "lat": 40.69968504,
     "lng": -89.6172325
    },
    {
     "lat": 40.69971069,
     "lng": -89.61720207
    },
   ]
   const path8=[
    {
     "lat": 40.69646667,
     "lng": -89.62215876
    },
    {
     "lat": 40.69646794,
     "lng": -89.62205649
    },
    {
     "lat": 40.69645159,
     "lng": -89.62205835
    },
    {
     "lat": 40.6964518,
     "lng": -89.62215917
    },
   ]
   const path9=[
    {
     "lat": 40.69646775,
     "lng": -89.61736688
    },
    {
     "lat": 40.6964471,
     "lng": -89.61737853
    },
    {
     "lat": 40.69645417,
     "lng": -89.61725267
    },
    {
     "lat": 40.6964731,
     "lng": -89.61726276
    },
   ]
   const path10=[
    {
     "lat": 40.69958685,
     "lng": -89.61295566
    },
    {
     "lat": 40.69951492,
     "lng": -89.6128327
    },
    {
     "lat": 40.69953283,
     "lng": -89.61280718
    },
    {
     "lat": 40.69961255,
     "lng": -89.61295545
    },
   ]
   const path11=[
    {
     "lat": 40.69896396,
     "lng": -89.61036396
    },
    {
     "lat": 40.69883637,
     "lng": -89.61036411
    },
    {
     "lat": 40.69883953,
     "lng": -89.61034431
    },
    {
     "lat": 40.69894866,
     "lng": -89.61034413
    },
   ]
   const path12=[
    {
     "lat": 40.69798379,
     "lng": -89.61326391
    },
    {
     "lat": 40.69796565,
     "lng": -89.613239
    },
    {
     "lat": 40.697967,
     "lng": -89.61312003
    },
    {
     "lat": 40.69798129,
     "lng": -89.61310737
    },
   ]
   const path13=[
    {
     "lat": 40.69969625,
     "lng": -89.61186254
    },
    {
     "lat": 40.69969078,
     "lng": -89.61165914
    },
    {
     "lat": 40.69970502,
     "lng": -89.61162303
    },
    {
     "lat": 40.6997102,
     "lng": -89.61189773
    },
   ]
   const path14=[
    {
     "lat": 40.6984789,
     "lng": -89.61222909
    },
    {
     "lat": 40.6984677,
     "lng": -89.61224787
    },
    {
     "lat": 40.69841392,
     "lng": -89.61204282
    },
    {
     "lat": 40.69842961,
     "lng": -89.61204347
    },
   ]
   const path15=[
    {
     "lat": 40.69971759,
     "lng": -89.6148502
    },
    {
     "lat": 40.69971489,
     "lng": -89.61449125
    },
    {
     "lat": 40.69972722,
     "lng": -89.61449442
    },
    {
     "lat": 40.69973082,
     "lng": -89.61487164
    },
   ]
   const path16=[
    {
     "lat": 40.6981651,
     "lng": -89.61323763
    },
    {
     "lat": 40.6981424,
     "lng": -89.61325564
    },
    {
     "lat": 40.69813722,
     "lng": -89.61308694
    },
    {
     "lat": 40.6981636,
     "lng": -89.61310483
    },
   ]
   const path17=[
    {
     "lat": 40.69843598,
     "lng": -89.62129743
    },
    {
     "lat": 40.69844966,
     "lng": -89.6213135
    },
    {
     "lat": 40.69833984,
     "lng": -89.62129977
    },
    {
     "lat": 40.6983437,
     "lng": -89.62128107
    },
   ]
   const path18=[
    {
     "lat": 40.69930072,
     "lng": -89.61052392
    },
    {
     "lat": 40.69920959,
     "lng": -89.61052785
    },
    {
     "lat": 40.69920762,
     "lng": -89.61051055
    },
    {
     "lat": 40.69930549,
     "lng": -89.61050617
    },
   ]
   const path19=[
    {
     "lat": 40.69815981,
     "lng": -89.61448019
    },
    {
     "lat": 40.69799828,
     "lng": -89.61453433
    },
    {
     "lat": 40.69801,
     "lng": -89.61450646
    },
    {
     "lat": 40.69814863,
     "lng": -89.61446075
    },
   ]
   const path20=[
    {
     "lat": 40.69844469,
     "lng": -89.62151682
    },
    {
     "lat": 40.6984326,
     "lng": -89.62153688
    },
    {
     "lat": 40.69843594,
     "lng": -89.62129821
    },
    {
     "lat": 40.69844963,
     "lng": -89.62131322
    },
   ]
   const path21=[
    {
     "lat": 40.69833897,
     "lng": -89.61931789
    },
    {
     "lat": 40.6983478,
     "lng": -89.61923433
    },
    {
     "lat": 40.69832434,
     "lng": -89.61922676
    },
    {
     "lat": 40.6983257,
     "lng": -89.61931343
    },
   ]
   const path22=[
    {
     "lat": 40.69870304,
     "lng": -89.61242394
    },
    {
     "lat": 40.69880569,
     "lng": -89.61258739
    },
    {
     "lat": 40.69887057,
     "lng": -89.61266644
    },
    {
     "lat": 40.69895404,
     "lng": -89.61274641
    },
    {
     "lat": 40.69896209,
     "lng": -89.61273468
    },
    {
     "lat": 40.69896997,
     "lng": -89.61273967
    },
    {
     "lat": 40.69896519,
     "lng": -89.6127582
    },
    {
     "lat": 40.69905531,
     "lng": -89.61281831
    },
    {
     "lat": 40.6991219,
     "lng": -89.61286044
    },
    {
     "lat": 40.69924456,
     "lng": -89.61290113
    },
    {
     "lat": 40.69957141,
     "lng": -89.61285971
    },
    {
     "lat": 40.69957123,
     "lng": -89.61200172
    },
    {
     "lat": 40.69870405,
     "lng": -89.61201375
    },
    {
     "lat": 40.69867309,
     "lng": -89.61215177
    },
    {
     "lat": 40.69861958,
     "lng": -89.61217429
    },
    {
     "lat": 40.69845658,
     "lng": -89.61200174
    },
    {
     "lat": 40.69843663,
     "lng": -89.6120169
    },
    {
     "lat": 40.69842839,
     "lng": -89.61199071
    },
    {
     "lat": 40.69844607,
     "lng": -89.61198068
    },
    {
     "lat": 40.69849764,
     "lng": -89.61193531
    },
    {
     "lat": 40.69858223,
     "lng": -89.61200246
    },
    {
     "lat": 40.69870993,
     "lng": -89.61194767
    },
    {
     "lat": 40.69880428,
     "lng": -89.61194882
    },
    {
     "lat": 40.69951315,
     "lng": -89.61193336
    },
    {
     "lat": 40.69961216,
     "lng": -89.61193369
    },
    {
     "lat": 40.69961872,
     "lng": -89.61288327
    },
    {
     "lat": 40.69958159,
     "lng": -89.61294063
    },
    {
     "lat": 40.69926941,
     "lng": -89.61295618
    },
    {
     "lat": 40.69915543,
     "lng": -89.61293266
    },
    {
     "lat": 40.69904014,
     "lng": -89.61287331
    },
    {
     "lat": 40.69894507,
     "lng": -89.61280256
    },
    {
     "lat": 40.69883553,
     "lng": -89.61270256
    },
    {
     "lat": 40.69874256,
     "lng": -89.61258439
    },
    {
     "lat": 40.69865594,
     "lng": -89.61241163
    },
    {
     "lat": 40.69863822,
     "lng": -89.61229983
    },
    {
     "lat": 40.69861093,
     "lng": -89.61217102
    },
    {
     "lat": 40.69868097,
     "lng": -89.6121412
    },
   ]
   const path23=[
    {
     "lat": 40.69974298,
     "lng": -89.61868492
    },
    {
     "lat": 40.69972617,
     "lng": -89.61865978
    },
    {
     "lat": 40.69970613,
     "lng": -89.61846033
    },
    {
     "lat": 40.69972652,
     "lng": -89.61845294
    },
   ]
   const path24=[
    {
     "lat": 40.69738342,
     "lng": -89.6148911
    },
    {
     "lat": 40.69736529,
     "lng": -89.61489181
    },
    {
     "lat": 40.69730177,
     "lng": -89.61450126
    },
    {
     "lat": 40.69731779,
     "lng": -89.61450102
    },
   ]
   const path25=[
    {
     "lat": 40.69845581,
     "lng": -89.62269211
    },
    {
     "lat": 40.69833055,
     "lng": -89.62268858
    },
    {
     "lat": 40.69834209,
     "lng": -89.62266506
    },
    {
     "lat": 40.69843746,
     "lng": -89.62265903
    },
   ]
   const path26=[
    {
     "lat": 40.69978027,
     "lng": -89.61930408
    },
    {
     "lat": 40.69976473,
     "lng": -89.61929027
    },
    {
     "lat": 40.69975068,
     "lng": -89.61922483
    },
    {
     "lat": 40.69977073,
     "lng": -89.61920612
    },
   ]
   const path27=[
    {
     "lat": 40.69985743,
     "lng": -89.61048202
    },
    {
     "lat": 40.69968688,
     "lng": -89.61052776
    },
    {
     "lat": 40.69966693,
     "lng": -89.61050102
    },
    {
     "lat": 40.69983541,
     "lng": -89.61046101
    },
    {
     "lat": 40.69987524,
     "lng": -89.61045165
    },
    {
     "lat": 40.69987953,
     "lng": -89.61047272
    },
   ]
   const path28=[
    {
     "lat": 40.69645595,
     "lng": -89.61326792
    },
    {
     "lat": 40.69644046,
     "lng": -89.61328242
    },
    {
     "lat": 40.69643975,
     "lng": -89.61314714
    },
    {
     "lat": 40.69645885,
     "lng": -89.61314829
    },
   ]
   const path29=[
    {
     "lat": 40.69809824,
     "lng": -89.6103693
    },
    {
     "lat": 40.69798206,
     "lng": -89.61037129
    },
    {
     "lat": 40.69798811,
     "lng": -89.61034793
    },
    {
     "lat": 40.69809707,
     "lng": -89.61034297
    },
   ]
   const path30=[
    {
     "lat": 40.69648613,
     "lng": -89.62152153
    },
    {
     "lat": 40.69646894,
     "lng": -89.62153171
    },
    {
     "lat": 40.69646361,
     "lng": -89.62133226
    },
    {
     "lat": 40.696478,
     "lng": -89.62133618
    },
   ]
   const path31=[
    {
     "lat": 40.69842728,
     "lng": -89.62061926
    },
    {
     "lat": 40.69844292,
     "lng": -89.62060927
    },
    {
     "lat": 40.69844289,
     "lng": -89.62075019
    },
    {
     "lat": 40.6984294,
     "lng": -89.62074893
    },
   ]
   const path32=[
    {
     "lat": 40.69844524,
     "lng": -89.6215178
    },
    {
     "lat": 40.69843275,
     "lng": -89.62153654
    },
    {
     "lat": 40.69834311,
     "lng": -89.62154162
    },
    {
     "lat": 40.69833728,
     "lng": -89.62152124
    },
   ]
   const path33=[
    {
     "lat": 40.69968757,
     "lng": -89.61052715
    },
    {
     "lat": 40.69966735,
     "lng": -89.61050153
    },
    {
     "lat": 40.69966153,
     "lng": -89.6103143
    },
    {
     "lat": 40.69968241,
     "lng": -89.61028638
    },
   ]
   const path34=[
    {
     "lat": 40.69870172,
     "lng": -89.61215124
    },
    {
     "lat": 40.69860822,
     "lng": -89.61219303
    },
    {
     "lat": 40.69860104,
     "lng": -89.61217262
    },
    {
     "lat": 40.69870296,
     "lng": -89.61213274
    },
   ]
   const path35=[
    {
     "lat": 40.69834182,
     "lng": -89.62008158
    },
    {
     "lat": 40.69833357,
     "lng": -89.62006012
    },
    {
     "lat": 40.69843406,
     "lng": -89.62006038
    },
    {
     "lat": 40.69842728,
     "lng": -89.62007694
    },
   ]
   const path36=[
    {
     "lat": 40.69842545,
     "lng": -89.61981144
    },
    {
     "lat": 40.69843002,
     "lng": -89.61983113
    },
    {
     "lat": 40.69833089,
     "lng": -89.61983322
    },
    {
     "lat": 40.69833909,
     "lng": -89.61981207
    },
   ]
   const path37=[
    {
     "lat": 40.6965162,
     "lng": -89.62005159
    },
    {
     "lat": 40.69649371,
     "lng": -89.62005316
    },
    {
     "lat": 40.69648094,
     "lng": -89.62005434
    },
    {
     "lat": 40.69646725,
     "lng": -89.62006426
    },
    {
     "lat": 40.69645556,
     "lng": -89.62007882
    },
    {
     "lat": 40.69644757,
     "lng": -89.62009492
    },
    {
     "lat": 40.69644345,
     "lng": -89.62011672
    },
    {
     "lat": 40.69645799,
     "lng": -89.6201163
    },
    {
     "lat": 40.69646414,
     "lng": -89.62009973
    },
    {
     "lat": 40.69647265,
     "lng": -89.62008539
    },
    {
     "lat": 40.69648928,
     "lng": -89.62007431
    },
    {
     "lat": 40.69651377,
     "lng": -89.62007352
    },
   ]
   const path38=[
    {
     "lat": 40.69815867,
     "lng": -89.61488424
    },
    {
     "lat": 40.69802677,
     "lng": -89.61489754
    },
    {
     "lat": 40.69789514,
     "lng": -89.61495571
    },
    {
     "lat": 40.69789604,
     "lng": -89.61492578
    },
    {
     "lat": 40.69803275,
     "lng": -89.61487168
    },
    {
     "lat": 40.69817097,
     "lng": -89.61486062
    },
   ]
   const path39=[
    {
     "lat": 40.69987963,
     "lng": -89.61183615
    },
    {
     "lat": 40.69986087,
     "lng": -89.61185315
    },
    {
     "lat": 40.69986126,
     "lng": -89.61167837
    },
    {
     "lat": 40.69987752,
     "lng": -89.61169003
    },
   ]
   const path40=[
    {
     "lat": 40.69644809,
     "lng": -89.6148575
    },
    {
     "lat": 40.69643309,
     "lng": -89.61484171
    },
    {
     "lat": 40.69643484,
     "lng": -89.61450751
    },
    {
     "lat": 40.6964555,
     "lng": -89.61452119
    },
   ]
   const path41=[
    {
     "lat": 40.69989264,
     "lng": -89.61311589
    },
    {
     "lat": 40.69970264,
     "lng": -89.61312394
    },
    {
     "lat": 40.69973384,
     "lng": -89.61308496
    },
    {
     "lat": 40.699864,
     "lng": -89.61308493
    },
   ]
   const path42=[
    {
     "lat": 40.69965684,
     "lng": -89.62001997
    },
    {
     "lat": 40.69963809,
     "lng": -89.62002119
    },
    {
     "lat": 40.69963812,
     "lng": -89.61983813
    },
    {
     "lat": 40.69966327,
     "lng": -89.61984058
    },
   ]
   const path43=[
    {
     "lat": 40.69965684,
     "lng": -89.62001997
    },
    {
     "lat": 40.69963809,
     "lng": -89.62002119
    },
    {
     "lat": 40.69963812,
     "lng": -89.61983813
    },
    {
     "lat": 40.69966327,
     "lng": -89.61984058
    }
   ]


// const paths = [
//   { lat: 40.6964827, lng: -89.6228149 },
//   { lat: 40.6963812, lng: -89.6103398 },
//   { lat: 40.6996933, lng: -89.610401 },
//   { lat: 40.6997933, lng: -89.6212807 },
//   { lat: 40.6995736, lng: -89.6222141 },
//   { lat: 40.6993947, lng: -89.6226647 },
//   { lat: 40.6989636, lng: -89.6227935 },
//   { lat: 40.6981339, lng: -89.6227935 },
//   { lat: 40.6964827, lng: -89.6228149 }
// ]
//  const path2=[
//   {lat:40.69969463,lng:-89.61331849},
//   {lat:40.69969737,lng:-89.61314277},
//   {lat:40.69972814,lng:-89.61310264},
//   {lat:40.69972274,lng:-89.61335881},
//   ]
//   const path3=[
//   {lat:40.69797581,lng:-89.61321511},
//   {lat:40.69795826,lng:-89.61318948},
//   {lat:40.69796236,lng:-89.61307062},
//   {lat:40.69797694,lng:-89.61305854},
//   ]
//   const path4=[
//   {lat:40.69632463,lng:-89.61994101},
//   {lat:40.69631053,lng:-89.61994524},
//   {lat:40.69631328,lng:-89.6197531},
//   {lat:40.69632968,lng:-89.61976083},
//   ]
//  const  path5=[
//   {lat:40.69936276,lng:-89.62203204},
//   {lat:40.699347,lng:-89.62209482},
//   {lat:40.69935922,lng:-89.62210589},
//   {lat:40.69938144,lng:-89.62201845},
//   ]
//  const path6=[
//   {lat:40.69960965,lng:-89.6174045},
//   {lat:40.69959333,lng:-89.61739395},
//   {lat:40.69958465,lng:-89.61724968},
//   {lat:40.69961099,lng:-89.61722029},
//   ]
//  const path7=[
//   {lat:40.69625401,lng:-89.62204537},
//   {lat:40.69625764,lng:-89.6219432},
//   {lat:40.69624125,lng:-89.62194441},
//   {lat:40.69623914,lng:-89.62204518},
//   ]
//  const path8=[
//   {lat:40.69636574,lng:-89.61725575},
//   {lat:40.69634482,lng:-89.61726657},
//   {lat:40.6963548,lng:-89.61714105},
//   {lat:40.69637349,lng:-89.61715189},
//   ]
//  const path9=[
//   {lat:40.69958526,lng:-89.6129709},
//   {lat:40.6995162,lng:-89.61284513},
//   {lat:40.69953469,lng:-89.61282034},
//   {lat:40.69961095,lng:-89.61297172},
//   ]
//  const path10=[
//   {lat:40.6990225,lng:-89.61035556},
//   {lat:40.69889496,lng:-89.61035062},
//   {lat:40.69889859,lng:-89.61033096},
//   {lat:40.69900767,lng:-89.61033513},
//   ]
//  const paths11=[
//   {lat:40.69797581,lng:-89.61321511},
//   {lat:40.69795826,lng:-89.61318948},
//   {lat:40.69796236,lng:-89.61307062},
//   {lat:40.69797694,lng:-89.61305854},
//   ]
//  const paths12=[
//   {lat:40.69971985,lng:-89.61188264},
//   {lat:40.69971908,lng:-89.61167912},
//   {lat:40.69973415,lng:-89.61164359},
//   {lat:40.69973299,lng:-89.61191838},
//   ]
//  const paths13=[
//   {lat:40.69849459,lng:-89.61220049},
//   {lat:40.69848297,lng:-89.61221882},
//   {lat:40.69843395,lng:-89.61201172},
//   {lat:40.69844961,lng:-89.61201299},
//   ]
//  const paths14=[
//   {lat:40.69967219,lng:-89.61486978},
//   {lat:40.69967778,lng:-89.61451089},
//   {lat:40.69969004,lng:-89.61451455},
//   {lat:40.69968493,lng:-89.61489173},
//   ]
//  const paths15=[
//   {lat:40.69815765,lng:-89.61319606},
//   {lat:40.69813455,lng:-89.61321316},
//   {lat:40.69813326,lng:-89.61304433},
//   {lat:40.69815922,lng:-89.61306327},
//   ]
//  const paths16=[
//   {lat:40.6982423,lng:-89.62126294},
//   {lat:40.6982556,lng:-89.62127955},
//   {lat:40.69814615,lng:-89.62126145},
//   {lat:40.69815044,lng:-89.62124291},
//   ]
//  const paths17=[
//   {lat:40.69935541,lng:-89.61052887},
//   {lat:40.69926423,lng:-89.61052917},
//   {lat:40.69926266,lng:-89.61051179},
//   {lat:40.69936059,lng:-89.61051132},
//   ]
//  const paths18=[
//   {lat:40.69812367,lng:-89.61443784},
//   {lat:40.69796097,lng:-89.61448552},
//   {lat:40.69797332,lng:-89.61445812},
//   {lat:40.69811295,lng:-89.61441796},
//   ]
//  const paths19=[
//   {lat:40.69824594,lng:-89.62148257},
//   {lat:40.69823339,lng:-89.62150214},
//   {lat:40.69824224,lng:-89.62126371},
//   {lat:40.69825557,lng:-89.62127926},
//   ]
//  const paths20=[
//   {lat:40.69819104,lng:-89.61928045},
//   {lat:40.6982018,lng:-89.61919728},
//   {lat:40.69817852,lng:-89.61918877},
//   {lat:40.69817789,lng:-89.61927546},
//   ]
//  const paths21=[
//   {lat:40.69871414,lng:-89.61240419},
//   {lat:40.69881296,lng:-89.61257166},
//   {lat:40.69887599,lng:-89.61265326},
//   {lat:40.69895757,lng:-89.61273652},
//   {lat:40.69896589,lng:-89.61272511},
//   {lat:40.69897365,lng:-89.61273041},
//   {lat:40.69896844,lng:-89.61274875},
//   {lat:40.69905714,lng:-89.61281243},
//   {lat:40.69912272,lng:-89.61285719},
//   {lat:40.69924439,lng:-89.61290275},
//   {lat:40.69957204,lng:-89.61287438},
//   {lat:40.69959167,lng:-89.61201677},
//   {lat:40.69872462,lng:-89.61199423},
//   {lat:40.69869048,lng:-89.61213095},
//   {lat:40.69863647,lng:-89.61215133},
//   {lat:40.69847754,lng:-89.61197236},
//   {lat:40.69845725,lng:-89.61198672},
//   {lat:40.69844962,lng:-89.61196021},
//   {lat:40.69846752,lng:-89.61195089},
//   {lat:40.69852011,lng:-89.6119076},
//   {lat:40.69860312,lng:-89.61197809},
//   {lat:40.69873202,lng:-89.61192842},
//   {lat:40.6988263,lng:-89.61193333},
//   {lat:40.6995352,lng:-89.61194613},
//   {lat:40.69963416,lng:-89.61195041},
//   {lat:40.69961879,lng:-89.61289982},
//   {lat:40.69958034,lng:-89.61295566},
//   {lat:40.69926795,lng:-89.61295876},
//   {lat:40.69915457,lng:-89.61293071},
//   {lat:40.6990407,lng:-89.6128668},
//   {lat:40.69894731,lng:-89.61279228},
//   {lat:40.69884013,lng:-89.61268796},
//   {lat:40.69874993,lng:-89.61256614},
//   {lat:40.69866734,lng:-89.61239},
//   {lat:40.69865221,lng:-89.61227755},
//   {lat:40.6986279,lng:-89.61214771},
//   {lat:40.6986986,lng:-89.6121207},
//   ]
//  const paths22=[
//   {lat:40.69960902,lng:-89.61870374},
//   {lat:40.6995928,lng:-89.61867794},
//   {lat:40.69957737,lng:-89.61847778},
//   {lat:40.69959793,lng:-89.61847121},
//   ]
//  const paths23=[
//   {lat:40.69733815,lng:-89.61481761},
//   {lat:40.69732002,lng:-89.61481759},
//   {lat:40.69726554,lng:-89.6144247},
//   {lat:40.69728156,lng:-89.61442509},
//   ]
//  const paths24=[
//   {lat:40.69822991,lng:-89.62265776},
//   {lat:40.69810479,lng:-89.62264924},
//   {lat:40.69811688,lng:-89.6226262},
//   {lat:40.69821234,lng:-89.62262396},
//   ]
//  const paths25=[
//   {lat:40.699632,lng:-89.6193241},
//   {lat:40.69961678,lng:-89.61930967},
//   {lat:40.69960425,lng:-89.6192437},
//   {lat:40.69962473,lng:-89.6192258},
//   ]
//  const paths26=[
//   {lat:40.69991284,lng:-89.61050918},
//   {lat:40.69974131,lng:-89.6105481},
//   {lat:40.69972198,lng:-89.61052058},
//   {lat:40.69989131,lng:-89.61048731},
//   {lat:40.69993133,lng:-89.61047953},
//   {lat:40.69993514,lng:-89.61050076},
//   ]
//  const paths27=[
//   {lat:40.69644859,lng:-89.61315821},
//   {lat:40.69643277,lng:-89.61317208},
//   {lat:40.69643519,lng:-89.61303684},
//   {lat:40.69645425,lng:-89.61303875},
//   ]
//  const paths28=[
//   {lat:40.69815706,lng:-89.61032638},
//   {lat:40.69804088,lng:-89.61032375},
//   {lat:40.69804747,lng:-89.61030064},
//   {lat:40.6981565,lng:-89.61030002},
//   ]
//  const paths29=[
//   {lat:40.69628817,lng:-89.62140921},
//   {lat:40.69627075,lng:-89.6214187},
//   {lat:40.69627003,lng:-89.62121913},
//   {lat:40.69628432,lng:-89.62122362},
//   ]
//  const paths30=[
//   {lat:40.69824926,lng:-89.62058473},
//   {lat:40.69826512,lng:-89.62057538},
//   {lat:40.69826184,lng:-89.62071623},
//   {lat:40.69824839,lng:-89.62071443},
//   ]
//  const paths31=[
//   {lat:40.69824647,lng:-89.62148357},
//   {lat:40.69823355,lng:-89.62150181},
//   {lat:40.69814383,lng:-89.62150331},
//   {lat:40.69813847,lng:-89.62148271},
//   ]
//  const paths32=[
//   {lat:40.69974201,lng:-89.61054752},
//   {lat:40.69972239,lng:-89.6105211},
//   {lat:40.69972089,lng:-89.61033373},
//   {lat:40.69974242,lng:-89.61030666},
//   ]
//  const paths33=[
//   {lat:40.69871911,lng:-89.61213156},
//   {lat:40.69862469,lng:-89.61216961},
//   {lat:40.69861798,lng:-89.61214892},
//   {lat:40.69872078,lng:-89.61211313},
//   ]
//  const paths34=[
//   {lat:40.69817626,lng:-89.62004389},
//   {lat:40.6981685,lng:-89.62002212},
//   {lat:40.69826894,lng:-89.62002638},
//   {lat:40.69826179,lng:-89.62004267},
//   ]
//  const paths35=[
//   {lat:40.69826609,lng:-89.61977721},
//   {lat:40.6982702,lng:-89.61979707},
//   {lat:40.69817107,lng:-89.61979522},
//   {lat:40.69817975,lng:-89.6197744},
//   ]
//  const paths36=[
//   {lat:40.69635217,lng:-89.61994115},
//   {lat:40.69632966,lng:-89.61994182},
//   {lat:40.69631687,lng:-89.61994249},
//   {lat:40.69630295,lng:-89.61995186},
//   {lat:40.69629093,lng:-89.61996595},
//   {lat:40.69628257,lng:-89.61998172},
//   {lat:40.69627795,lng:-89.62000335},
//   {lat:40.69629249,lng:-89.62000351},
//   {lat:40.69629902,lng:-89.61998719},
//   {lat:40.69630786,lng:-89.6199732},
//   {lat:40.69632473,lng:-89.61996278},
//   {lat:40.69634924,lng:-89.61996297},
//   ]
//  const paths37=[
//   {lat:40.69811321,lng:-89.61484166},
//   {lat:40.69798106,lng:-89.6148497},
//   {lat:40.69784815,lng:-89.61490258},
//   {lat:40.69784973,lng:-89.61487271},
//   {lat:40.69798763,lng:-89.61482409},
//   {lat:40.69812604,lng:-89.61481854},
//   ]
//  const paths38=[
//   {lat:40.69990375,lng:-89.61186358},
//   {lat:40.69988461,lng:-89.61187982},
//   {lat:40.69988903,lng:-89.61170514},
//   {lat:40.69990502,lng:-89.61171744},
//   ]
//  const paths39=[
//   {lat:40.69640403,lng:-89.61474674},
//   {lat:40.6963894,lng:-89.61473036},
//   {lat:40.69639886,lng:-89.61439639},
//   {lat:40.69641921,lng:-89.61441088},
//   ]
//  const paths40=[
//   {lat:40.69988721,lng:-89.61314324},
//   {lat:40.69969711,lng:-89.61314371},
//   {lat:40.6997292,lng:-89.61310599},
//   {lat:40.69985929,lng:-89.61311116},
//   ]
//  const paths41=[
//   {lat:40.6994921,lng:-89.62003473},
//   {lat:40.69947332,lng:-89.62003521},
//   {lat:40.69947758,lng:-89.61985224},
//   {lat:40.69950266,lng:-89.61985569},
//   ]
const options = {
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "#003300",
  strokeOpacity: 1,
  strokeWeight: 4,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
}
const optionsteleop = {
    fillColor: "transparent",
    fillOpacity: 1,
    strokeColor: "rgb(220,53,69)",
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }
  const optionsteleoptwo = {
    fillColor: "transparent",
    fillOpacity: 1,
    strokeColor: "#7859bc",
    strokeOpacity: 1,
    strokeWeight: 4,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }
  
const robotpathoptions={
  fillColor: "transparent",
  fillOpacity: 1,
  strokeColor: "rgb(113, 207, 98)",
  strokeOpacity: 1,
  strokeWeight: 8,
  clickable: false,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1
}

const onLoad = polygon => {
  // console.log("polygon: ", polygon);
}

const GoogleMapMain = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
            defaultZoom={15}
            defaultCenter={{lat:parseFloat(props.robotlatitude), lng:parseFloat(props.robotlongitude)}}   
            yesIWantToUseGoogleMapApiInternals
            > 
             <KmlLayer
               url={`${kmlurl}`}>
            </KmlLayer>
                {console.log('kmlurlkmlurlkmlurlkmlurl',kmlurl)}
               {props.commandlistdata?.length <=0 ? 
                 <Marker position={{lat:props.robotlatitude,lng:props.robotlongitude}} icon={{
                    url: '/assets/images/robot-icon-pickup_1.svg',
                    scaledSize: new window.google.maps.Size(30, 30),
                    }} 
                    >
                 </Marker>
                 :null }
                 
                     <Polygon
                        onLoad={onLoad}
                        paths={props.nogozones}
                        options={options}
                      /> 
                   
                 <Polygon
                        onLoad={onLoad}
                        paths={props.geofencezone}
                        options={optionsteleop}
                      /> 
                    {props?.fleetzones?.map((item)=>{
                      return (  <Polygon
                                  onLoad={onLoad}
                                  paths={item}
                                  options={optionsteleoptwo} /> )
                      })} 
                     {/* {props.commandlistdata?.length >0?     
                      <Marker  position={{lat:parseFloat(props.commandlistdata[0]?.latitude),lng:parseFloat(props.commandlistdata[0]?.longitude)}} icon={{
                        url: '/assets/images/robot-icon-dropoff.svg',
                        scaledSize: new window.google.maps.Size(30, 30),
                        }}>
                      </Marker>:null}    
                      {props.commandlistdata?.length >0? 
                        <Polyline path={[{lat:parseFloat(props.commandlistdata[0]?.latitude), lng:parseFloat(props.commandlistdata[0]?.longitude)}, {lat: parseFloat(props.robotlatitude), lng:parseFloat(props.robotlongitude)}]} 
                        geodesic={true}
                        options={{
                            strokeColor: "#ed716c",
                            strokeOpacity: 0.75,
                            strokeWeight: 2,
                        }}
                          />
                      :null} */}
                      {props.robotpathcheck ? null:  <Polyline
                                     geodesic={true}
                                    onLoad={onLoad}
                                    path={props.robotpathmain}
                                    options={robotpathoptions}
              />}
              {console.log('props.commandlistdata', props.commandlistdata)}
              {props.commandlistdata?.length >0 ?
                 props.commandlistdata.map((item)=>{
                if(item.actionPriority === 1 || item.actionPriority === 0 ){
         return  <><Marker position={destination} icon={{
                            url: '/assets/images/robot-icon-pickup_1.svg',
                            scaledSize: new window.google.maps.Size(30, 30),
                            }}     />
                     <Marker position={origin} icon={{
                                url: '/assets/images/robot-icon-dropoff_1.svg',
                                scaledSize: new window.google.maps.Size(30, 30),
                            }} />

                            {googlemapcon(props)}
                  
                  {/* {console.log('directions',directions,'destination',destination,'origin',origin)} */}
                  {props.robotpathcheck  && item.actionType ==="MOVELOCATION"?
                  
                  <DirectionsRenderer
                  directions={directions}
                  options={{
                      suppressMarkers: true,
                      // markerOptions: { icon: image },
                      polylineOptions: {
                        stokeColor: "#ed716c",
                        strokeOpacity:0.5,
                        fill:'#ed716c',
                        strokeWeight: 10,
                      },
                  }}    
                  /> :  <Polyline
                  geodesic={true}
                  onLoad={onLoad}
                  path={props.robotpathmain}
                  options={robotpathoptions}
              />}
                  {/* <DistanceMatrixService 
                  options={{
                    destinations: [{ lat: parseFloat(props.commandlistdata[0]?.latitude), lng: parseFloat(props.commandlistdata[0]?.longitude)}],
                    origins: [{ lat: parseFloat(props.robotlatitude), lng: parseFloat(props.robotlongitude) }],
                    travelMode: "WALKING",
                  }}
                  callback = {(response) => {
                  console.log('Distance response',response)
                  localStorage.setItem('Distance', response.rows[0].elements[0].distance.text)
                  } }
                  /> */}
                  </>
                            }
                          })
                          :null}
                         
               
    </GoogleMap>
))

export default React.memo(GoogleMapMain)